import "./education.css";

export default function EducationCard({education}) {
    return (
    <div className="educationCard">
        <img src={education.imgSrc} alt="OLU" />
        <div className="edTitleContainer">
            <h1>{education.schoolName}</h1>
            <h2>{education.diploma}</h2>
        </div>
        <div className="timeLocContainer">
            <h3>{education.location}</h3>
            <h3>{education.date}</h3>
            <h3>{education.expectedDate}</h3>
            
        </div>
    </div>
    )
}