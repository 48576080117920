import './infoCard.css'

export default function InfoCard({data}) {
  return (
    <div className="infoCardContainer">
      <h1>{data.title}</h1>
      <p>{data.paragraph}</p>
    </div>
  );
}
