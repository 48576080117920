import { useEffect, useState } from "react";
import "../../components/educationCard/education.css";
import { database } from "../../firebaseAppInit";
import { collection, query, orderBy, getDocs } from "firebase/firestore";
import InfoCard from "../../components/infoCard/infoCard";

export default function OrganzitionSection() {

    const [orgs, setOrgs] = useState([]);

    useEffect(() => {
        const fetchOrgs = async () => {
            const orgsArray = [];
            const orgDocs = await getDocs(query(collection(database, "Organizations"), orderBy("order")));
            orgDocs.forEach((doc) => {
                const data = doc.data();
                const orgData = {
                    title: data.title,
                    paragraph: data.paragraph,
                }
                orgsArray.push(
                    <InfoCard key={doc.id} data = {orgData}/>
                )
            })
            setOrgs(orgsArray);
        }
        fetchOrgs();
    }, [])
    
    return (
        <div className="educationSection">
            {orgs}
        </div>
    )
}