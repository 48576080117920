import "./expCard.css";

export default function ExperienceCard({ data }) {
  return (
    <div className="educationSection">
      <div className="expCardContainer">
        <div className="titleDate">
          <h1>{data.title}</h1>
          <h3>{data.date}</h3>
        </div>
        <p className="bodyContent">{data.paragraph}</p>
      </div>
    </div>
  );
}
