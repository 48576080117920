import ExperienceCard from "../../components/expCard/expCard";
import { database } from "../../firebaseAppInit";
import { useState, useEffect } from "react";
import { collection, query, orderBy, getDocs } from "firebase/firestore";

export default function Involvements() {
  const [orgs, setOrgs] = useState([]);

  useEffect(() => {
      const fetchorgs = async () => {
          
      const orgsArray = [];
      const orgDocs = await getDocs(
        query(collection(database, "Experience"), orderBy("order"))
      );
          orgDocs.forEach((doc) => {
          console.log("looping through");
        const data = doc.data();
        const orgData = {
          title: data.title,
          paragraph: data.paragraph,
          date: data.date,
        };
        orgsArray.push(<ExperienceCard key={doc.id} data={orgData} />);
      });
          
      setOrgs(orgsArray);
    };
    fetchorgs();
  }, []);
  return <div>{orgs}</div>;
}
