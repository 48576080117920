import "./App.css";
import HomePage from "./pages/home/home";

function App() {
  return (
    <main>
      <HomePage/>
    </main>
  );
}

export default App;
