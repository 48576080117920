import { useEffect, useState } from "react";
import "../../components/educationCard/education.css";
import { database } from "../../firebaseAppInit";
import { collection, query, orderBy, getDocs } from "firebase/firestore";
import EducationCard from "../../components/educationCard/education";

export default function EducationSection() {

    const [schools, setSchools] = useState([]);

    useEffect(() => {
        const fetchSchools = async () => {
            const schoolsArray = [];
            const schoolDocs = await getDocs(query(collection(database, "Education"), orderBy("order")));
            schoolDocs.forEach((doc) => {
                const data = doc.data();
                const schoolData = {
                    schoolName: data.schoolName,
                    diploma: data.diploma,
                    imgSrc: data.imgSrc,
                    location: data.location,
                    date: data.date,
                    expectedDate: data.expectedDate, 
                }
                schoolsArray.push(
                    <EducationCard key={doc.id} education = {schoolData}/>
                )
            })
            setSchools(schoolsArray);
        }
        fetchSchools();
    }, [])
    
    return (
        <div className="educationSection">
            {schools}
        </div>
    )
}