import "./home.css";
import EducationSection from "../educationSection/educationSection.jsx";
import Personality from "../personalitySection/pSection.jsx";
import OrganzitionSection from "../organizationSection/orgSection.jsx";
import Involvements from "../InvolmentsSection/invSection.jsx";


export default function HomePage() {
  return (
    <div className="homePageContainer">
      <div className="infoContainer">
        <img className="AbbyPic" src="/Abby.jpg" alt="Abby" />
        <div className="innerContainer">
          <h1>Hi! I'm Abby</h1>
          <h2>2nd Year Aerospace Engineer - Cal Poly SLO</h2>
          <h2>Here You'll Find Information About My Professional Life</h2>
          <div className="linkContainer">
            <a href="who" className="linkListObject">
              Who I Am
            </a>
            <p className="linkListObject">-</p>
            <a className="linkListObject" href="experience">
              Experience
            </a>
            <p className="linkListObject">-</p>
            <a className="linkListObject" href="experience">
              {" "}
              Resume
            </a>
            <p className="linkListObject">-</p>
            <a className="linkListObject" href="contact">
              {" "}
              Contact
            </a>
          </div>
        </div>
      </div>
      <h1 className="break" href="who">Who I Am</h1>
      <div className="contentContainer" id="personalityContainer">
        <Personality/>
      </div>
      <h1 className="break" href="education">My Education</h1>
      <div className="contentContainer" id="educationContainer">
        <EducationSection/>
      </div>
      <h1 className="break" href="experience">Organizations I Am Involved With</h1>
      <div className="contentContainer">
        <OrganzitionSection/>
      </div>
      <h1 className="break" href="experience">Work Experience</h1>
      <div className="contentContainer">
        <Involvements/>
      </div>
      {/* <h1 className="break" href="contact">Contact</h1>
      <ContactForm/> */}
    </div>
  );
}
