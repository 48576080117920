import { useEffect, useState } from "react";
import { collection, query, getDocs, orderBy } from "firebase/firestore";
import { database } from "../../firebaseAppInit";
import InfoCard from "../../components/infoCard/infoCard";

export default function Personality() {
  const [cards, setCards] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const array = [];
      const docs = await getDocs(
        query(collection(database, "AboutMe"), orderBy("order"))
      );
      docs.forEach((doc) => {
        const docData = doc.data();
        const obj = {
          title: docData.title,
          paragraph: docData.paragraph,
        };
        array.push(<InfoCard key={doc.id} data={obj} />);
      });
      setCards(array);
    };
    fetchData();
  }, []);

  return <div className="contentContainer">{cards}</div>;
}
