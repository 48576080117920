import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyAW6Ksd5tMXlCUo-HpDPZ8ySxhc5NuFl8g",
  authDomain: "abby-s-website.firebaseapp.com",
  projectId: "abby-s-website",
  storageBucket: "abby-s-website.appspot.com",
  messagingSenderId: "815068231769",
  appId: "1:815068231769:web:42fc7d1d3f75fda8e55948",
};

const app = initializeApp(firebaseConfig);
const database = getFirestore(app);

export {database};